import { omit } from "lodash";
import { FETCH_JOBS, REMOVE_JOB } from "actions";

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_JOBS:
      return { ...state, ...action.payload };
    case REMOVE_JOB:
      return omit(state, action.payload);
    default:
      return state;
  }
}
