import { map, uniqWith, isEqual, size, remove, flatten, find, filter, sumBy, omit } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Typography,
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  List,
  Toolbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useConfirm } from "components/confirm";

import JobItem from "components/jobItem";
import JobItemEdit from "components/jobItemEdit";
import JobReport from "components/jobReport";
import AssetNumbers from "components/itemAssetNumbers";
import Cert from "components/cert";
import { CSVLink } from "react-csv";

import { fetchJobItems, fetchJobReports, deleteJobItem } from "actions";

const headers = [
  { label: "Description", key: "description" },
  { label: "Material", key: "material" },
  { label: "Qty", key: "count" },
  { label: "Ft", key: "length" }
];

const Job = ({ onClose }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [bomList, setBOMList] = useState([])
  const [itemDialog, setItemDialog] = useState(false);
  const [assetNoDialog, setAssetNoDialog] = useState(false);
  const [clone, setClone] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const jobItems = useSelector((state) => state.jobItems);
  const jobId = useSelector((state) => state.openJobId);
  const job = useSelector((state) => state.jobs[jobId]);
  const [openJobReport, setOpenJobReport] = useState({});
  const toggleJobReport = (type) => {
    setOpenJobReport((prev) => (Boolean(prev.abbr) ? {} : type));
  };
  const getMaterial = (type) => {
    const allConnections = flatten(map(jobItems, type));
    const filteredConnections = map(allConnections, c => omit(c, 'materialId', 'traceDoc', 'traceNo'))
    const uniqueConnections = uniqWith(filteredConnections, isEqual);
    const result = map(uniqueConnections, conn => {
      const count = size(remove(filteredConnections, c => isEqual(c,conn)));
      const relevantItems = filter(jobItems, item => {
        const bomArray = item[type];
        const hasConn = find(bomArray, mat => {
          const equal = isEqual(omit(mat, 'materialId', 'traceDoc', 'traceNo'), conn)
          return equal
          })
        return size(hasConn) > 0 && item.length > 0 && type === 'bodies'
      })
      const toFeet = map(relevantItems, item => (
        { ...item, length: item.lengthUnits === "IN" 
          ? item.length/12
          : item.length
        }
        ))
      const length = sumBy(toFeet, 'length')
      return { ...conn, count, length }
    })
    return result
  }
  const getBOMList = () => {
    const connections = getMaterial('connections')
    const bodies = getMaterial('bodies');
    setBOMList([...bodies, ...connections])
  }
  const fetch = React.useCallback(() => {
    dispatch(fetchJobItems(jobId));
    dispatch(fetchJobReports(jobId));
  }, [dispatch, jobId]);
  useEffect(() => {
    fetch();
  }, [fetch, jobId]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleItemDialog = (id) => {
    setEditItemId((prev) => (prev ? id || null : null));
    setItemDialog((prev) => !prev);
  };
  const toggleItemMenu = (e, id) => {
    setMenuAnchorEl(e ? e.target : null);
    setEditItemId(id ? id : null);
  };
  const toggleAssetNoDialog = () => setAssetNoDialog((prev) => !prev);
  const dlDataBookURLs = () => {
    return map(jobItems, (item, itemId) => {
      if (item.dataBook) return document.getElementById(`${itemId}dlLink`)
    })
  }
  const dlDataBooks = () => {
    const urlEls = dlDataBookURLs()
    urlEls.forEach(function (value, idx) {
        const response = {
          file: value,
        };
        setTimeout(() => {
            window.location.href = response.file;
        }, idx * 100)
    })
  }
  
  React.useEffect(getBOMList, [jobItems])
  
  return (
    jobId && (
      <Dialog fullScreen open={Boolean(jobId)} onClose={onClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {job.date}: {job.description}
            </Typography>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  toggleItemDialog();
                  handleClose();
                }}
              >
                Add Job Item
              </MenuItem>
              <MenuItem
                onClick={() => {
                  toggleAssetNoDialog();
                  handleClose();
                }}
              >
                Assign Asset Numbers
              </MenuItem>
              <MenuItem>
                <Cert />
              </MenuItem>
                <CSVLink
                  data={bomList}
                  headers={headers}
                  target="_blank"
                  filename={"bom_sheet.csv"}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <MenuItem>
                      Download BOM
                  </MenuItem>
                </CSVLink>
              
            </Menu>
          </Toolbar>
        </AppBar>
        <List>
          <JobItem item="" header />
          {map(jobItems, (item, itemId) => {
            return (
              item.jobId === jobId && (
                <JobItem
                  item={item}
                  itemId={itemId}
                  key={itemId}
                  onClick={() => toggleItemDialog(itemId)}
                  toggleItemMenu={toggleItemMenu}
                  toggleJobReport={toggleJobReport}
                />
              )
            );
          })}
        </List>
        <JobItemEdit
          open={itemDialog}
          onClose={() => {
            toggleItemDialog();
            toggleItemMenu();
            setClone(false);
          }}
          editId={editItemId}
          jobId={jobId}
          clone={clone}
        />
        <Menu
          open={Boolean(menuAnchorEl)}
          onClose={() => toggleItemMenu()}
          anchorEl={menuAnchorEl}
        >
          <MenuItem
            onClick={() => {
              toggleItemDialog(editItemId);
            }}
          >
            Edit Item
          </MenuItem>
          <MenuItem
            onClick={() => {
              toggleItemDialog(editItemId);
              setClone(true);
            }}
          >
            Clone Item
          </MenuItem>
          <MenuItem
            onClick={() => {
              confirm({
                description: "Deleting this item cannot be undone",
                confirmationText: "Yes, delete",
              })
                .then(() => {
                  toggleItemMenu();
                  dispatch(deleteJobItem(editItemId));
                })
                .catch(() => toggleItemMenu());
            }}
          >
            Delete Item
          </MenuItem>
          {editItemId && jobItems[editItemId].dataBook
          &&<MenuItem
            component="a"
            href={jobItems[editItemId].dataBook.downloadUrl}
          >Data Book</MenuItem>}
        </Menu>
        <JobReport
          open={Boolean(openJobReport.abbr)}
          onClose={toggleJobReport}
          type={openJobReport}
          jobId={jobId}
        />
        <AssetNumbers open={assetNoDialog} onClose={toggleAssetNoDialog} />
      </Dialog>
    )
  );
};

export default Job;
