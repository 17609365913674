import { map, includes, chain, size } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { IconButton, Grid, Divider } from "@mui/material";
import { green, amber } from "@mui/material/colors";
import MenuIcon from "@mui/icons-material/Menu";
import CheckCircleIcon from "@mui/icons-material/TaskAlt";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const JobItem = ({ item, itemId, header, toggleItemMenu, toggleJobReport }) => {
  const theme = useTheme();
  const [traceInfo, setTraceInfo] = React.useState(["untraced"]);
  const { reportTypes } = useSelector((s) => s);
  const { jobReports } = useSelector((s) => s);
  const partsTrace = React.useCallback(() => {
    const findTrace = (part) => {
      return map(part, (c) => {
        return c.materialId || "untraced";
      });
    };
    return setTraceInfo([
      ...findTrace(item.connections),
      ...findTrace(item.bodies),
    ]);
  }, [item]);
  React.useEffect(partsTrace, [partsTrace]);
  const itemAsset = () => {
    return item.coc ? (
      <a
        href={item.coc.downloadUrl}
        target="_blank"
        rel="noopener noreferrer"
      >{`(${item.asset})`}</a>
    ) : (
      `(${item.asset})`
    );
  };
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ textAlign: "center" }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                [theme.breakpoints.up("sm")]: {
                  paddingLeft: theme.spacing(1),
                  textAlign: "left",
                },
              }}
            >
              {!header && `${item.description}`}{" "}
              <span>{item.asset ? itemAsset() : ""}</span>
            </Grid>
            {map(reportTypes, (t) => {
              const type = t.abbr;
              const hasNeed = includes(item.needs, type);
              const filterReport = chain(jobReports)
                .pickBy((r) => r.type.abbr === type)
                .pickBy((r) => Boolean(r.items[itemId]))
                .value();

              const onReport =
                type === "MTRs"
                  ? !includes(traceInfo, "untraced")
                  : size(filterReport) > 0;
              return (
                <Grid item xs={2} sm={1} key={type}>
                  {header ? (
                    type
                  ) : (
                    <IconButton /*color={hasNeed ? 'success' : 'warning'}*/
                      onClick={() =>
                        toggleJobReport(
                          onReport
                            ? {
                                ...t,
                                reportId: Object.keys(filterReport)[0],
                                itemId,
                              }
                            : { ...t, itemId }
                        )
                      }
                    >
                      {hasNeed ? (
                        onReport ? (
                          <CheckCircleIcon
                            sx={{
                              color: (theme) =>
                                green[
                                  theme.palette.mode === "dark" ? 300 : 600
                                ],
                            }}
                          />
                        ) : (
                          <ErrorOutlineIcon
                            sx={{
                              color: (theme) =>
                                amber[
                                  theme.palette.mode === "dark" ? 300 : 600
                                ],
                            }}
                          />
                        )
                      ) : onReport ? (
                        <CheckCircleIcon />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </IconButton>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={1}>
          {!header && (
            <IconButton onClick={(e) => toggleItemMenu(e, itemId)} id="target">
              <MenuIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default JobItem;
