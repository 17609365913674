import React, { useContext, createContext, useState } from "react";
import { createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const ColorModeContext = createContext();

export const useColorSelect = () => {
  const color = useContext(ColorModeContext);
  return color;
};

export const ColorModeProvider = ({ children, defaultOptions = {} }) => {
  // const theme = useTheme();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState(prefersDarkMode ? "dark" : "light");
  React.useEffect(
    () => setMode(prefersDarkMode ? "dark" : "light"),
    [prefersDarkMode]
  );
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
      theme: () =>
        createTheme({
          palette: {
            mode,
          },
        }),
    }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={{ ...colorMode, mode }}>
      {children}
    </ColorModeContext.Provider>
  );
};
