import React from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import thunk from "redux-thunk";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { ColorModeProvider, useColorSelect } from "colorMode";
import GlobalStyles from "@mui/material/GlobalStyles";

import rootReducer from "reducers";
import "index.css";
import { ConfirmProvider } from "components/confirm";
import JobsMain from "components/jobsMain";
import SignIn from "components/signin";
import PrivateRoute from "components/auth";
import * as serviceWorker from "serviceWorker";

import { verifyAuth } from "./actions";

export function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );

  store.dispatch(verifyAuth());

  return store;
}

const ColorProvided = () => {
  const { theme } = useColorSelect();
  return (
    <ThemeProvider theme={theme()}>
      <ConfirmProvider>
        <GlobalStyles
          styles={(theme) => {
            return {
              "*::-webkit-scrollbar": {
                width: "0.4em",
              },
              "*::-webkit-scrollbar-track": {
                "-webkit-box-shadow": `${theme.shadows[1]}`,
              },
              "*::-webkit-scrollbar-thumb": {
                backgroundColor: `${theme.palette.background.paper}`,
                outline: `1px solid ${theme.palette.primary.main}`,
                borderRadius: "2px",
              },
              "& body": {
                backgroundColor: `${theme.palette.background.paper}ee`,
              },
            };
          }}
        />
        <BrowserRouter>
          <Switch>
            <PrivateRoute exact path="/main" component={JobsMain} />
            <Route path="/" component={SignIn} />
          </Switch>
        </BrowserRouter>
      </ConfirmProvider>
    </ThemeProvider>
  );
};

const store = configureStore();
function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={4}>
        <ColorModeProvider>
          <ColorProvided />
        </ColorModeProvider>
      </SnackbarProvider>
    </Provider>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
