import { StyleSheet, Font } from "@react-pdf/renderer";
import { register } from "fonts/font";

// Font.register({
//   family: 'Merriweather',
//   src: merriweather,
// });

// Font.register({
//   family: 'Domine',
//   fonts: [
//     {src: domine, fontWeight: 'normal'},
//     {src: domineBold, fontWeight: 'bold'},
//   ]
// });

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 16,
    border: 1,
    borderColor: "#ffffff",
    borderStyle: "solid",
  },
  viewBoxOuter: {
    margin: 16,
    position: "absolute",
  },
  viewBox: {
    border: 2,
    borderColor: "#1F497D",
    padding: 2,
    borderStyle: "solid",
    width: "100%",
    height: "100%",
    position: "relative",
  },
  header: {
    marginTop: 6,
    marginBottom: -25,
  },
  line: {
    height: 2,
    position: "fixed",
    top: 67,
    borderBottom: 2,
    borderColor: "#1F497D",
    borderStyle: "double",
    marginRight: 20,
    marginLeft: 20,
    zIndex: 99,
  },
  logo: {
    marginLeft: 35,
    width: 150,
    zIndex: -1,
  },
  contact: {
    color: "#1F497D",
    flexDirection: "column",
    fontSize: 12,
    position: "fixed",
    top: -96,
    left: 442,
    textDecoration: "none",
  },
  titleRow: {
    border: 0.75,
    marginRight: 20,
    marginLeft: 20,
    marginTop: -37,
    marginBottom: 30,
    fontWeight: "bold",
    // borderRadius: 3
  },
  title: {
    postiton: "absolute",
    width: 225,
    top: -11.5,
    left: 295,
    textAlign: "center",
    fontSize: 16,
    fontFamily: "Merriweather",
    color: "#1F497D",
    backgroundColor: "white",
  },
  sectionContainer: {
    paddingBottom: 30,
  },
  section: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: -10,
    //marginBottom: 60,
    border: 0.75,
    // borderRadius: 3,
    minHeight: 16,
    fontFamily: "Domine",
    flexDirection: "column",
  },
  sectionTitle: {
    position: "absolute",
    top: -8,
    left: 16,
    fontSize: 12,
    backgroundColor: "white",
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: "bold",
    color: "#1F497D",
  },
  sectionBody: {
    marginTop: 10,
    marginBottom: 5,
    marginLeft: 20,
    marginRight: 20,
    fontSize: 9,
    lineHeight: 1.3,
    // flex: 1,
    // display: 'flex',
    flexDirection: "column",
    // flexGrow: 1,
    // flexBasis: 'auto'
    //justifyContent: 'center'
  },
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  bold: {
    fontWeight: "bold",
    fontSize: 10,
    marginLeft: 7,
  },
  columns: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  column: {
    //width: '50%',
    flexDirection: "column",
    // flexWrap: "wrap",
  },
  charts: {
    //width: '50%',
    flexDirection: "row",
    flexWrap: "wrap",
  },
  chart: {
    width: 120,
  },
  scan: {
    maxWidth: 150,
    maxHeight: 70,
  },
  scanImg: {
    objectFit: "scale-down",
  },
  readings: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  readingKey: {
    marginLeft: 15,
    fontSize: 9,
    textAlign: "right",
    width: 60,
  },
  reading: {
    marginLeft: 2,
    marginRight: 2,
    fontSize: 8,
    textAlign: "center",
    width: 35,
  },
  cell: {
    margin: 0.5,
  },
  link: {
    marginLeft: 5,
  },
  certify: {
    fontFamily: "Merriweather",
    fontStyle: "italic",
    fontSize: 9,
  },
  approve: {
    flexDirection: "row",
    // marginLeft: 140,
    postion: "absolute",
    // top: -5,
    left: 90,
    marginBottom: -23,
    marginTop: -5,
    overflow: "visible",
  },
  itemImages: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    //justifyContent: 'space-around',
    display: "flex",
  },
  itemImage: {
    width: 175,
    maxHeight: 120,
    padding: 8,
  },
  itemPic: {
    objectFit: "contain",
  },
  spacing: {
    flexGrow: 1,
    flexBasis: "auto",
    display: "flex",
  },
  finalSection: {
    position: "absolute",
  },
  final: {
    bottom: 0,
    left: 0,
  },
  percentWidth: (p) => ({ width: `${p}%` }),
  underline: {
    borderBottom: 1,
    borderBottomColor: "gray",
    marginBottom: 6,
  },
  qa: {
    // paddingRight: 20,
    flexDirection: "column",
    // flexWrap: "wrap",
    // justifyContent: "center",
    alignItems: "center",
  },
  cocDate: {
    fontSize: 10,
    marginTop: -19.5,
    marginLeft: 12
  }
});
