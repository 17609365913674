import React from "react";

export function useToday() {
  const [today, setToday] = React.useState(null);

  const todayFormatted = () => {
    const rn = new Date();
    const y = rn.getFullYear();
    const m = `0${rn.getMonth() + 1}`.slice(-2);
    const d = `0${rn.getDate()}`.slice(-2);
    return setToday(`${y}-${m}-${d}`);
  };

  React.useEffect(() => {
    todayFormatted();
  }, []);

  return today;
}
