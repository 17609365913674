import { map, chain, values } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Tabs, Tab } from "@mui/material";
import { CSVLink } from 'react-csv';

import { fetchMaterials, fetchSettings } from "actions";

import MaterialsCategory from "components/materialsCategory";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const Materials = () => {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const materials = useSelector((state) => state.materials);
  const materialCategories = chain(materials)
    .map((m) => m.type)
    .uniq()
    .sort()
    .value();
  const fetch = React.useCallback(() => {
    dispatch(fetchMaterials());
    dispatch(fetchSettings());
  }, [dispatch]);
  React.useEffect(fetch, [fetch]);
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <CSVLink
          data={values(materials)}
          >
          Download Inventory List
        </CSVLink>
        <Tabs value={value} onChange={handleChange}>
          {map(materialCategories, (category, categoryIndex) => {
            return <Tab label={category} key={category} />;
          })}
        </Tabs>
      </Box>
      {map(materialCategories, (category, categoryIndex) => {
        return (
          <TabPanel value={value} index={categoryIndex} key={category}>
            <MaterialsCategory type={category} />
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default Materials;
