import React from "react";
import { useDispatch } from "react-redux";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useColorSelect } from "colorMode";

import SettingsDetail from "components/settingsDetail";

import { fetchSettings } from "actions";

const Settings = (props) => {
  const { toggleColorMode, mode } = useColorSelect();
  const dispatch = useDispatch();
  const fetch = React.useCallback(() => {
    dispatch(fetchSettings());
  }, [dispatch]);
  React.useEffect(fetch, []);
  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={mode === "dark"} onChange={toggleColorMode} />
          }
          label="Dark Mode"
        />
      </FormGroup>
      <Accordion>
        <AccordionSummary>Connections</AccordionSummary>
        <AccordionDetails
          sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
        >
          <SettingsDetail type="connections" />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Materials</AccordionSummary>
        <AccordionDetails
          sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
        >
          <SettingsDetail type="materials" />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Bodies</AccordionSummary>
        <AccordionDetails
          sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
        >
          <SettingsDetail type="bodies" />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Ports</AccordionSummary>
        <AccordionDetails
          sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
        >
          <SettingsDetail type="ports" />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>Units of Measure</AccordionSummary>
        <AccordionDetails
          sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
        >
          <SettingsDetail type="um" />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Settings;
