/*global structuredClone*/
// import { map, pickBy } from "lodash";
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import ItemMtrsTrace from "components/itemMtrsTrace";

import {
  fetchMaterials,
  updateJobItem,
  removeMaterialInventory,
} from "actions";

const ItemMtrs = ({ open, onClose, type }) => {
  const { itemId } = type;
  const dispatch = useDispatch();
  const [localItem, setLocalItem] = React.useState({});
  // const materials = useSelector((state) => state.materials);
  const item = useSelector((state) => state.jobItems[itemId]);
  const fetch = React.useCallback(() => {
    dispatch(fetchMaterials());
  }, [dispatch]);
  React.useEffect(fetch, [fetch]);
  React.useEffect(() => {
    setLocalItem(structuredClone(item));
  }, [item]);
  const update = () => {
    dispatch(
      removeMaterialInventory(localItem, { ...item }, () => {
        dispatch(updateJobItem(localItem, itemId, onClose));
      })
    );
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Item Mtrs</DialogTitle>
      <DialogContent>
        <ItemMtrsTrace
          item={localItem}
          setLocalItem={setLocalItem}
          traceType="connections"
        />
        <ItemMtrsTrace
          item={localItem}
          setLocalItem={setLocalItem}
          traceType="bodies"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={update} variant="contained" color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemMtrs;
