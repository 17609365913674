import { map, orderBy, mapKeys, values as _values, mapValues } from "lodash";
import React, { useState } from "react";
import copy from "copy-to-clipboard";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Switch
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";

import { batchUpdateJobItems } from "actions";

const AssetNumbers = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const jobItems = useSelector((state) => state.jobItems);
  const [localItems, setLocalItems] = useState([]);
  const [orderByDescription, setOrderByDescription] = useState(false);
  const theme = useTheme();
  React.useEffect(() => {
    const orderByValue = orderByDescription ? "description" : "id"
    const localJobItems = mapKeys(
      orderBy(
        map(jobItems, (jobItem, jobItemId) => {
          return { ...jobItem, id: jobItemId };
        }),
        orderByValue
      ),
      (ji) => ji.id
    );
    return setLocalItems(localJobItems);
  }, [jobItems, orderByDescription]);
  const copyDescriptions = () => {
    const copied = copy(
      map(localItems, (li) => `${li.description}`).join("\n"),
      { format: "text/plain" }
    );
    if (copied) enqueueSnackbar("Descriptions copied to clipboard");
    return;
  };
  const populateAll = () => {
    const firstAsset = _values(localItems)[0];
    if (!firstAsset) return null;
    let firstAssetNo = firstAsset.asset - 1;
    const newAssetNos = mapValues(localItems, (localItem, localItemId) => {
      firstAssetNo++;
      return { ...localItem, asset: firstAssetNo };
    });
    setLocalItems(newAssetNos);
  };
  const updateAssets = () => {
    return dispatch(batchUpdateJobItems(localItems, onClose));
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={3}>
            {" "}
            Asset
          </Grid>
          <Grid item xs={5}>
            {" "}
            Description
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel control={<Switch checked={orderByDescription} onChange={() =>setOrderByDescription(p => !p)} />} label="Sort by Description" />
          </Grid>
          <Grid item xs={3}>
            {" "}
            <Button onClick={populateAll}> Populate All</Button>
          </Grid>
          <Grid item xs={9}>
            {" "}
            <Button onClick={copyDescriptions}>Copy Descriptions</Button>
          </Grid>
          {map(localItems, (localItem, localItemKey) => {
            const { asset, description, id } = localItem;
            return (
              <Grid key={id} item xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <TextField
                      value={asset}
                      type="number"
                      sx={{
                        "& input": {
                          padding: theme.spacing(1),
                        },
                      }}
                      onChange={(e) => {
                        setLocalItems({
                          ...localItems,
                          [localItemKey]: {
                            ...localItems[localItemKey],
                            asset: e.target.value,
                          },
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sx={{
                      paddingLeft: theme.spacing(2),
                      lineHeight: theme.spacing(4),
                    }}
                  >
                    {description}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={updateAssets} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssetNumbers;
