import { map, pickBy, capitalize } from "lodash";
import React from "react";
import {
  TextField,
  Grid,
  Card,
  Typography,
  MenuItem,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

const ItemMtrs = ({ item, traceType, setLocalItem }) => {
  const theme = useTheme();
  const materials = useSelector((state) => state.materials);
  return (
    <Card
      sx={{
        marginBottom: theme.spacing(1),
        backgroundColor: `${theme.palette.background.paper}44`,
        padding: theme.spacing(1),
      }}
      square
    >
      <Typography sx={{ textAlign: "center" }}>
        {capitalize(traceType)}
      </Typography>
      <Divider />
      <Grid container spacing={1}>
        {map(item[traceType], (trace, traceIndex) => {
          const relevantMaterials = pickBy(
            materials,
            (m) => m.description === trace.description
          );
          const { materialId } = item[traceType][traceIndex];
          return (
            <Grid item xs={12} sm={6} key={traceIndex}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    {trace.description}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    {trace.material}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    value={
                      relevantMaterials[materialId] ? materialId || "" : ""
                    }
                    onChange={(e) => {
                      item[traceType][traceIndex].materialId = e.target.value;
                      setLocalItem({ ...item });
                    }}
                    fullWidth
                    label="Traceability Number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    //sx={{marginTop: theme => theme.spacing(1)}}
                  >
                    <MenuItem value="" />
                    {map(relevantMaterials, (rm, rmId) => {
                      if(rm.qty>0 || materialId === rmId) {
                        return (
                          <MenuItem key={rmId} value={rmId}>
                            {rm.traceNo} (PO: {rm.po}) Qty: {rm.qty}
                          </MenuItem>
                        );
                      }
                    })}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

export default ItemMtrs;
