import React from "react";
import { Route } from "react-router-dom";
import { isAuth } from "firebase_config";
import SignIn from "components/signin";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const user = isAuth();
      if (user) {
        return <Component {...props} />;
      }
      return <SignIn to="/" path={props.match.path} />;
    }}
  />
);

export default PrivateRoute;
