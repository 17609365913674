import { SET_OPEN_JOB } from "actions";

const INITIAL_STATE = null;

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_OPEN_JOB:
      return action.payload;
    default:
      return state;
  }
}