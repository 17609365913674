import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
} from "@mui/material";
import { Formik, Form } from "formik";
import { useConfirm } from "components/confirm";
import { useToday } from "customHooks";

import { createJob, updateJob, deleteJob } from "actions";

const JobsNew = ({ open, onClose, editId }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const editJob = useSelector((state) => state.jobs[editId]);
  const today = useToday();
  return (
    <Formik
      enableReinitialize
      initialValues={
        editId
          ? editJob
          : {
              date: today,
              description: "",
              due: "",
              status: "open",
            }
      }
      onSubmit={(values, actions) => {
        const cb = () => {
          actions.resetForm();
          onClose();
        };
        return editId
          ? dispatch(updateJob(values, cb))
          : dispatch(createJob(values, cb));
      }}
    >
      {({ values, errors, handleChange, resetForm }) => {
        return (
          <Dialog open={open} onClose={onClose}>
            <Form>
              <DialogTitle>{editId ? "Edit Job" : "Create Job"}</DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: (theme) => theme.spacing(1) }}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={values.date}
                      name="date"
                      label="Date"
                      type="date"
                      disabled={Boolean(editId)}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={values.due}
                      name="due"
                      label="Due Date"
                      type="date"
                      disabled={Boolean(editId)}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={values.description}
                      name="description"
                      label="Job Desciption"
                      onChange={handleChange}
                      multiline
                      rows={2}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={values.status}
                      name="status"
                      label="Status"
                      onChange={handleChange}
                      select
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    >
                      <MenuItem value="open">Open</MenuItem>
                      <MenuItem value="closed">Closed</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={onClose}>
                  Cancel
                </Button>
                {editId && (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      confirm()
                        .then(() =>
                          dispatch(
                            deleteJob(editId, () => {
                              resetForm();
                              onClose();
                            })
                          )
                        )
                        .catch();
                    }}
                  >
                    Delete
                  </Button>
                )}
                <Button type="submit" color="primary" variant="contained">
                  {editId ? "Update" : "Create"}
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default JobsNew;
