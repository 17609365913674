import { FETCH_JOB_ITEMS } from "actions";

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_JOB_ITEMS:
      return action.payload;
    default:
      return state;
  }
}
