import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const config = {
  apiKey: "AIzaSyCWm0ZmQfgmqEqGuFFQqfkewU58d2V-14c",
  authDomain: "custom-app-project-test.firebaseapp.com",
  databaseURL: "https://custom-app-project-test.firebaseio.com/",
  projectId: "custom-app-project-test",
  storageBucket: "custom-app-project-test.appspot.com",
  messagingSenderId: "981995534626",
};

const firebaseApp = initializeApp(config);

export const database = getDatabase(firebaseApp);
export const storage = getStorage(firebaseApp);

const key =
  "firebase:authUser:AIzaSyCWm0ZmQfgmqEqGuFFQqfkewU58d2V-14c:[DEFAULT]";
const localUser = JSON.parse(window.localStorage.getItem(key));

export const auth = getAuth(firebaseApp);

export const USER = () => auth.currentUser;

export const isAuth = () => {
  return auth.currentUser || localUser;
};
