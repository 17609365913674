import { map } from "lodash";
import {
  ref,
  child,
  get,
  onValue,
  query,
  orderByChild,
  equalTo,
  push,
  set,
  remove,
  runTransaction,
  update,
  off,
} from "firebase/database";
import {
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { database, storage, auth } from "firebase_config";
import convert from "convert-units";

export const AUTH_USER = "AUTH_USER";
export const UNAUTH_USER = "UNAUTH_USER";

export const FETCH_JOBS = "FETCH_JOBS";
export const REMOVE_JOB = "REMOVE_JOB";
export const FETCH_JOB_ITEMS = "FETCH_JOB_ITEMS";
export const FETCH_ITEM_TYPES = "FETCH_ITEM_TYPES";
export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_MATERIALS = "FETCH_MATERIALS";
export const FETCH_JOB_REPORT = "FETCH_JOB_REPORT";
export const FETCH_JOB_REPORTS = "FETCH_JOB_REPORTS";
export const SET_OPEN_JOB = "SET_OPEN_JOB";

const dbRef = ref(database);
const assetConstruction = child(dbRef, "assetConstruction");
const users = child(dbRef, "users/users");
const buildJobs = child(assetConstruction, "jobs");
const jobItems = child(assetConstruction, "jobItems");
const jobReports = child(assetConstruction, "jobReports");
const jobItemTypes = child(assetConstruction, "jobItemTypes");
const settings = child(assetConstruction, "settings");
const materials = child(assetConstruction, "materials");

// =================================
// ==========Job Actions===========
// =================================

export function fetchJobs(closed) {
  return (dispatch) => {
    const includeClosed = closed
      ? buildJobs
      : query(query(buildJobs, orderByChild("status")), equalTo("open"));
    onValue(includeClosed, (snapshot) => {
      dispatch({
        type: FETCH_JOBS,
        payload: snapshot.val(),
      });
    });
  };
}

export function createJob(values, cb) {
  return () => {
    const jobId = push(buildJobs).key;
    set(child(buildJobs, jobId), {
      ...values,
      jobId,
    }).then(cb);
  };
}

export function updateJob(values, cb) {
  return (dispatch) => {
    const { jobId } = values;
    set(child(buildJobs, jobId), {
      ...values,
      jobId,
    }).then(() => {
      cb();
      dispatch(fetchJobs(false))
      });
  };
}
export function deleteJob(jobId, cb) {
  return (dispatch) => {
    remove(child(buildJobs, jobId)).then(() => {
      dispatch({
        type: REMOVE_JOB,
        payload: jobId,
      });
      return cb();
    });
  };
}

export function setOpenJobId(id) {
  return dispatch => {
    dispatch({
      type: SET_OPEN_JOB,
      payload: id
    })
  }
}

// =================================
// ==========Item Actions===========
// =================================

export function fetchJobItems(jobId) {
  return (dispatch) => {
    onValue(
      query(query(jobItems, orderByChild("jobId")), equalTo(jobId)),
      (snapshot) => {
        if (!jobId) return;
        dispatch({
          type: FETCH_JOB_ITEMS,
          payload: snapshot.val(),
        });
      }
    );
  };
}

export function cancelJobItemFetch(jobId) {
  return () =>
    off(query(query(jobItems, orderByChild("jobId")), equalTo(jobId)));
}

export function createJobItem(values, cb) {
  return () => push(jobItems, values);
}

export function updateJobItem(values, itemId, cb) {
  return () => update(child(jobItems, itemId), values).then(cb);
}

export function batchUpdateJobItems(values, cb) {
  return () => update(jobItems, values).then(cb);
}
export function deleteJobItem(itemId, cb) {
  return () => remove(child(jobItems, itemId));
}

export function fetchItemTypes() {
  return (dispatch) => {
    onValue(jobItemTypes, (snapshot) => {
      dispatch({
        type: FETCH_ITEM_TYPES,
        payload: snapshot.val(),
      });
    });
  };
}

// =================================
// ==========Report Actions===========
// =================================

export function fetchJobReports(jobId) {
  return (dispatch) => {
    onValue(
      query(query(jobReports, orderByChild("jobId")), equalTo(jobId)),
      (snapshot) => {
        dispatch({
          type: FETCH_JOB_REPORTS,
          payload: snapshot.val(),
        });
      }
    );
  };
}

export function fetchJobReport(reportId) {
  return (dispatch) => {
    onValue(child(jobReports, reportId), (snapshot) => {
      dispatch({
        type: FETCH_JOB_REPORT,
        payload: { [reportId]: snapshot.val() },
      });
    });
  };
}

export function updateJobReport(values, reportId, cb) {
  return () => set(child(jobReports, reportId), values).then(cb);
}

export function createJobReport(values, cb) {
  return () => push(jobReports, values);
}
// =================================
// ========Materials Actions========
// =================================
export function fetchMaterials(all) {
  return (dispatch) =>
    onValue(materials, (snapshot) => {
      dispatch({
        type: FETCH_MATERIALS,
        payload: snapshot.val(),
      });
    });
}

export function deleteMaterial(id, cb) {
  return () => remove(child(materials, id)).then(cb);
}

export function removeMaterialInventory(localItem, currentItem, cb) {
  const updateQtys = (type) => {
    return map(localItem[type], async (part, partIndex) => {
      const { materialId } = part;
      const prevMaterialId =
        currentItem[type][partIndex] && currentItem[type][partIndex].materialId;
      const isSameMaterial = prevMaterialId === materialId;
      if (isSameMaterial) return null;
      const { length, lengthUnits } = localItem;
      const getMaterialUm = (matId) =>
        matId && get(child(materials, matId)).then((s) => s.val().um);
      const materialUm = await getMaterialUm(materialId);
      const prevMaterialUm = await getMaterialUm(prevMaterialId);
      const isSame = lengthUnits === materialUm;
      const isBody = type === "bodies";
      const hasLength = length > 0;
      return runTransaction(child(materials, `${materialId}/qty`), (count) => {
        if (!materialId) return null;
        //if it has a length, switch from decrement to to subtraction.  if length units are different, convert then subtract
        const newCount = //eslint-disable-line no-unused-vars
          isBody && hasLength
            ? (count =
                count -
                (isSame
                  ? length
                  : convert(length)
                      .from(lengthUnits.toLowerCase())
                      .to(materialUm.toLowerCase())))
            : count--;
        return count;
      }).then(() => {
        return runTransaction(
          child(materials, `${prevMaterialId}/qty`),
          (count) => {
            if (!prevMaterialId) return null;
            const newCount = //eslint-disable-line no-unused-vars
              isBody && hasLength
                ? (count =
                    count +
                    (isSame
                      ? length
                      : convert(length)
                          .from(lengthUnits.toLowerCase())
                          .to(prevMaterialUm.toLowerCase())))
                : count++;
            return count;
          }
        );
      });
    });
  };

  return () => {
    //CONNECTIONS
    updateQtys("connections");
    //BODIES
    updateQtys("bodies");
    cb();
  };
}

export function setMaterial(values, cb) {
  const id = values.id || push(materials).key;
  return () => {
    set(child(materials, id), { ...values, id }).then(cb);
  };
}

// =================================
// ========Settings Actions=========
// =================================

export function setSettingValue(type, values, cb) {
  const id = values.id || push(child(settings, type)).key;
  const setting = child(child(settings, type), id);
  return () => set(setting, { ...values, id }).then(cb);
}

export function fetchSettings() {
  return (dispatch) =>
    onValue(settings, (snapshot) => {
      dispatch({
        type: FETCH_SETTINGS,
        payload: snapshot.val(),
      });
    });
}
export function setItemType(values, cb) {
  const hasId = Boolean(values.itemTypeId);
  const key = hasId ? values.itemTypeId : push(jobItemTypes).key;
  return () => set(child(jobItemTypes, key), values).then(() => cb(key));
}
// =================================
// ========Storage Actions==========
// =================================

export function uploadCert(itemId, asset, file, attachmentLocations, cb) {
  const fullPath = `assetConstruction/cocs/${itemId}/COC_${asset}.pdf`;
  return (dispatch) => {
    const fileRef = storageRef(storage, fullPath);

    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          const coc = {
            fileLocation: fullPath,
            downloadUrl,
            fileName: `COC_${asset}.pdf`,
          };
          dispatch(
            updateJobItem({ coc, attachmentLocations }, itemId, () =>
              console.log("item updated")
            )
          );
        });
      }
    );
  };
}

export function uploadFile(uploadLocation, file, cb) {
  const uniqKey = push(jobItems).key;
  const fullPath = `${uploadLocation}/${uniqKey}/${file.name}`;
  return () => {
    const fileRef = storageRef(storage, fullPath);
    const uploadTask = uploadBytesResumable(fileRef, file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          cb({
            fileLocation: fullPath,
            downloadUrl,
            fileName: file.name,
          });
        });
      }
    );
  };
}

export function deleteFile(fullFilePath, cb) {
  return () => {
    const fileRef = storageRef(storage, fullFilePath);
    deleteObject(fileRef)
      .then(cb)
      .catch((e) => console.log(e));
  };
}
// =================================
// ==========Auth Actions===========
// =================================

export function signIn(values, cb) {
  const { email, password } = values;
  return () =>
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        cb({ type: "User", res });
      })
      .catch((res) => cb({ type: "Error", res }));
}

export function logoutUser(cb) {
  return (dispatch) =>
    signOut(auth)
      .then(cb)
      .catch((err) => cb(err));
}

export function verifyAuth() {
  return (dispatch) => {
    const key =
      "firebase:authUser:AIzaSyCWm0ZmQfgmqEqGuFFQqfkewU58d2V-14c:[DEFAULT]";
    onAuthStateChanged(auth, (user) => {
      if (user && user.uid) {
        const uid = user.uid;
        const uChild = child(users, uid);
        get(uChild).then((snapshot) => {
          const dbUser = snapshot.val();
          const userString = JSON.stringify(user);
          window.localStorage.setItem(key, userString);
          dispatch({
            type: AUTH_USER,
            payload: { ...user, ...dbUser },
          });
        });
      } else {
        window.localStorage.removeItem(key);
        dispatch({
          type: UNAUTH_USER,
        });
      }
    });
  };
}
