import { FETCH_JOB_REPORTS, FETCH_JOB_REPORT } from "actions";

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_JOB_REPORT:
      return { ...state, ...action.payload };
    case FETCH_JOB_REPORTS:
      return action.payload;
    default:
      return state;
  }
}
