const INITIAL_STATE = [
  { abbr: "MTRs", desc: "Material Test Reports" },
  { abbr: "HBW", desc: "Hardness Test Reports" },
  { abbr: "UT/RT", desc: "Ultrasonic/Radiographic Exam Reports" },
  { abbr: "MPI", desc: "Magnetic Particle Exam Reports" },
  { abbr: "HT", desc: "Stress Relieve Reports" },
  { abbr: "HYDRO", desc: "Hydrostatic Test Reports" },
];

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    default:
      return state;
  }
}
