import { map, filter, size } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Formik, Form } from "formik";
import { object, string } from "yup";

import { deleteFile, setItemType } from "actions";

import FileDrop from "components/fileDrop";

const ItemType = ({ open, onClose, editId, reopenOnSave }) => {
  const dispatch = useDispatch();
  const itemType = useSelector((state) => state.itemTypes[editId]);
  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={
          itemType || {
            description: "",
            connections: 1,
            bodies: 1,
            ports: 0,
            schematics: [],
          }
        }
        validationSchema={object().shape({
          description: string().required(),
        })}
        onSubmit={(values, actions) => {
          const updateValues = { ...values, itemTypeId: editId };
          const cb = () => {
            actions.resetForm();
            onClose();
          };
          return dispatch(setItemType(updateValues, cb));
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          touched,
          handleBur,
        }) => {
          const handleImageDelete = (index) => {
            const arr = filter(values.schematics, (v, i) => i !== index);
            const { fileLocation } = values.schematics[index];
            const cb = () => setFieldValue("schematics", arr);
            dispatch(deleteFile(fileLocation, cb));
          };
          return (
            <Form>
              <DialogTitle>
                {editId ? "Update Item Type" : "Create Item Type"}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Item Type Description"
                      value={values.description}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      sx={{ marginTop: (theme) => theme.spacing(1) }}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="connections"
                      value={values.connections}
                      type="number"
                      label="Connections"
                      fullWidth
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="bodies"
                      value={values.bodies}
                      type="number"
                      label="Bodies"
                      fullWidth
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="ports"
                      value={values.ports}
                      type="number"
                      label="Ports"
                      fullWidth
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  {itemType ? (
                    <>
                      <Grid item xs={12}>
                        <FileDrop
                          saveLocation="assetConstruction/itemTypeImages"
                          onUpload={({
                            fileLocation,
                            downloadUrl,
                            fileName,
                          }) => {
                            values.schematics.push({
                              fileLocation,
                              downloadUrl,
                              fileName,
                            });
                            setFieldValue("schematics", values.schematics);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ImageList
                          sx={{ width: 500, height: 450 }}
                          cols={3}
                          rowHeight={164}
                        >
                          {map(values.schematics, (item, itemIndex) => (
                            <ImageListItem key={item.downloadUrl}>
                              <img
                                src={`${item.downloadUrl}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${item.downloadUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.fileName}
                                loading="lazy"
                              />
                              <ImageListItemBar
                                title={item.title}
                                subtitle={item.author}
                                actionIcon={
                                  <IconButton
                                    sx={{ color: "rgba(255, 255, 255, 0.9)" }}
                                    aria-label={`info about ${item.title}`}
                                    onClick={() => handleImageDelete(itemIndex)}
                                  >
                                    <DeleteForeverIcon />
                                  </IconButton>
                                }
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        onClick={() => {
                          size(errors) === 0 &&
                            dispatch(setItemType(values, reopenOnSave));
                        }}
                      >
                        Save Item Type to Add Schematics
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" color="primary" variant="contained">
                  {editId ? "Update" : "Create"}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ItemType;
