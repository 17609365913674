import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { storage } from 'firebase_config';
import {
  pdf,
  Page,
  Text,
  View,
  Document,
  Image,
  Link,
  // PDFViewer,
  Svg,
  Path,
  G,
} from "@react-pdf/renderer";
import logo from "images/gp_logo.png";
import signature from "images/signature.png";
// import pass from "images/pass.png";
// import moment from 'moment';
import { fetchMaterials } from "actions";
// import { register } from "fonts/font";
import { useSnackbar } from "notistack";
import CheckIcon from "@mui/icons-material/Check";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { green } from "@mui/material/colors";
import { styles } from "components/cert.styles.jsx";

import { uploadCert } from "actions";

// const imageStorage = storage.ref('repair_work');

// import Resizer from 'react-image-file-resizer';
// import Working from 'components/working';

// Font.register({
//   family: 'Merriweather',
//   src: merriweather,
// });

// Font.register({
//   family: 'Domine',
//   fonts: [
//     {src: domine, fontWeight: 'normal'},
//     {src: domineBold, fontWeight: 'bold'},
//   ]
// });

function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

const reformatDate = (dbDate) => {
  if (typeof dbDate !== "string") return null;
  const y = dbDate.substr(0, 4);
  const m = dbDate.substr(5, 2);
  const d = dbDate.substr(8, 2);
  return `${m}/${d}/${y}`;
};

Object.defineProperty(String.prototype, "fixLink", {
  value: function () {
    var link = this;
    var start = link.search("/repair_work") + 1;
    return start
      ? link.substr(0, start) + link.substr(start).replace(/\//g, "%2F")
      : link;
  },
});

const QA = ({ width, report, label }) => {
  if (!report) return null;
  const { title, date, files, by } = report;
  return (
    <View style={{ ...styles.percentWidth(width), ...styles.qa }}>
      <View style={styles.underline}>
        <Text>{label}</Text>
      </View>
      <View style={styles.column}>
        <View style={styles.row}>
          {_.size(files) > 1 ? (
            <View style={styles.row}>
              <Text>Report: {title}</Text>
              {_.map(files, (file) => {
                return (
                  <Link
                    src={file.downloadUrl}
                    key={file.downloadUrl}
                    style={{ marginTop: -2, marginLeft: 1 }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Svg width="12" height="12">
                      <G transform="scale(.5)">
                        <Path
                          d={
                            FilePresentIcon.type.render().props.children.props.d
                          }
                          stroke="#0066CC"
                        />
                      </G>
                    </Svg>
                  </Link>
                );
              })}
            </View>
          ) : (
            <Text>
              {" "}
              Report:{" "}
              <Link
                src={files[0] && files[0].downloadUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {title}
              </Link>
            </Text>
          )}
        </View>
        <Text>By: {by}</Text>
        <Text>Date: {reformatDate(date)}</Text>
      </View>
    </View>
  );
};
const Certificate = ({ item, itemReports, customer, materials }) => {
  if (!item) return null;
  const bodies = isIterable(item.bodies) ? item.bodies : [];
  const connections = isIterable(item.connections) ? item.connections : [];
  const bom = [...connections, ...bodies];
  const uniqBOM = _.uniqWith(bom, _.isEqual);
  const qtyBOM = _.map(uniqBOM, (bomItem) => {
    const filtered = _.filter(bom, (filterEl) => _.isEqual(filterEl, bomItem));
    const qty = _.size(filtered);
    return { ...bomItem, qty };
  });
  const hbw = _.find(itemReports, (r) => r.type && r.type.abbr === "HBW");
  const ut = _.find(itemReports, (r) => r.type && r.type.abbr === "UT/RT");
  const mpi = _.find(itemReports, (r) => r.type && r.type.abbr === "MPI");
  const ht = _.find(itemReports, (r) => r.type && r.type.abbr === "HT");
  const hydro = _.filter(itemReports, (r) => r.type && r.type.abbr === "HYDRO");
  const dates = _.map(itemReports, report => report.date);
  const recent = dates.sort()[dates.length-1]
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={[styles.viewBox, styles.viewBoxOuter]} fixed>
          <View style={{ ...styles.viewBox, paddingBottom: 10 }} fixed></View>
        </View>
        <View style={styles.header} fixed>
          <View style={styles.line}></View>
          <Image fixed cache style={styles.logo} src={logo} />
          <View style={styles.contact}>
            <Text>1537 Coteau Rd.</Text>
            <Text>Houma, LA 70364</Text>
            <Text>PH: (985) 879-1002</Text>
            <Text>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                src="http://www.gulf-pro.com"
              >
                www.gulf-pro.com
              </Link>
            </Text>
          </View>
        </View>
        <View style={styles.titleRow}>
          <View style={styles.title}>
            <Text>Certificate of Conformance</Text>
          </View>
          <View style={styles.cocDate}><Text>Date: {reformatDate(recent)}</Text></View>
        </View>
        <View style={styles.sectionContainer} wrap={false}>
          <View style={styles.section} fixed={false}>
            <View style={styles.sectionTitle}>
              <Text>Item</Text>
            </View>
            <View style={styles.sectionBody}>
              <View style={styles.row}>
                <Text>Asset/Serial Number:</Text>
                <Text style={styles.bold}>{item.asset}</Text>
              </View>
              <View style={styles.row}>
                <Text>Description: </Text>
                <Text style={styles.bold}>{item.description}</Text>
              </View>
              {item.mfgSpec ? (
                <View style={styles.row}>
                  <Text>Manufacturing Spec: </Text>
                  <Text style={styles.bold}>{item.mfgSpec}</Text>
                </View>
              ) : null}
              {item.mfg ? (
                <View style={styles.row}>
                  <Text>Manufacturer: </Text>
                  <Text style={styles.bold}>{item.oem}</Text>
                </View>
              ) : null}
              {item.pressureRating ? (
                <View style={styles.row}>
                  <Text>Pressure Rating (MAWP): </Text>
                  <Text style={styles.bold}>{item.pressureRating} psi</Text>
                </View>
              ) : null}
              {item.temperature ? (
                <View style={styles.row}>
                  <Text>Temperature Rating: </Text>
                  <Text style={styles.bold}>{item.temperature}</Text>
                </View>
              ) : null}
              {item.serviceType ? (
                <View style={styles.row}>
                  <Text>Service Type: </Text>
                  <Text style={styles.bold}>{item.serviceType}</Text>
                </View>
              ) : null}
            </View>
          </View>
        </View>

        <View style={styles.sectionContainer} wrap={false}>
          <View style={styles.section}>
            <View style={styles.sectionTitle}>
              <Text>BOM</Text>
            </View>
            <View style={styles.sectionBody}>
              <View style={{ ...styles.row, ...styles.underline }}>
                <View style={styles.percentWidth(10)}>
                  <Text>Qty</Text>
                </View>
                <View style={styles.percentWidth(40)}>
                  <Text>Description</Text>
                </View>
                <View style={styles.percentWidth(25)}>
                  <Text>Grade</Text>
                </View>
                <View style={styles.percentWidth(25)}>
                  <Text>Trace No.</Text>
                </View>
              </View>
              {_.map(qtyBOM, (connection, connectionIndex) => {
                const { materialId } = connection;
                if (!materials[materialId]) return null;
                const { material, traceNo, traceDocs } = materials[materialId];
                return (
                  <View style={styles.row} key={connectionIndex+traceNo}>
                    <View style={styles.percentWidth(10)}>
                      <Text>{connection.qty}</Text>
                    </View>
                    <View style={styles.percentWidth(40)}>
                      <Text>{connection.description}</Text>
                    </View>
                    <View style={styles.percentWidth(25)}>
                      <Text>{material}</Text>
                    </View>
                    <View style={styles.percentWidth(25)}>
                      <Text>
                        {traceDocs && traceDocs[0] ? (
                          <Link
                            href={traceDocs[0].downloadUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {traceNo}
                          </Link>
                        ) : (
                          traceNo
                        )}
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
        {ht && (
          <View style={styles.sectionContainer} wrap={false}>
            <View style={styles.section} fixed={false}>
              <View style={styles.sectionTitle}>
                <Text>Stress Relieve</Text>
              </View>
              <View style={styles.sectionBody}>
                <View style={styles.row}>
                  {_.size(ht.files) > 1 ? (
                  <View>
                      <Text>Report: {ht.title}</Text>
                    <View style={{ flexDirection: 'row' }}>
                      {_.map(ht.files, (file) => {
                        return (
                          <Link
                            src={file.downloadUrl}
                             key={file.downloadUrl} 
                            style={{ marginTop: -2, marginLeft: 1, flexDirection: 'row' }}
                          >
                            <Svg width="12" height="12">
                              <G transform="scale(.5)">
                                <Path
                                  d={
                                    FilePresentIcon.type.render().props.children
                                      .props.d
                                  }
                                  stroke="#0066CC"
                                />
                              </G>
                            </Svg>
                          </Link>
                        );
                      })}
                          </View>
                    </View>
                  ) : (
                    <Text>
                      {" "}
                      Report:{" "}
                      <Link src={ht.files[0] && ht.files[0].downloadUrl}>
                        {ht.title}
                      </Link>
                    </Text>
                  )}
                </View>
                <View style={styles.row}>
                  <Text>By:</Text>
                  <Text style={styles.bold}>{ht.by}</Text>
                </View>
                <View style={styles.row}>
                  <Text>Date:</Text>
                  <Text style={styles.bold}>{reformatDate(ht.date)}</Text>
                </View>
              </View>
            </View>
          </View>
        )}
        <View style={styles.sectionContainer} wrap={false}>
          <View style={styles.section}>
            <View style={styles.sectionTitle}>
              <Text>Quality Assurance</Text>
            </View>
            <View style={styles.sectionBody}>
              <View style={styles.row}>
                <View style={{ ...styles.percentWidth(13), ...styles.qa }}>
                  <View style={styles.underline}>
                    <Text>Dimensional</Text>
                  </View>
                  <View>
                    <Svg width="24" height="24">
                      <Path
                        d={CheckIcon.type.render().props.children.props.d}
                        stroke={green[700]}
                      />
                    </Svg>
                  </View>
                </View>
                <View style={{ ...styles.percentWidth(12), ...styles.qa }}>
                  <View style={styles.underline}>
                    <Text>Serialized</Text>
                  </View>
                  <View>
                    <Svg width="24" height="24">
                      <Path
                        d={CheckIcon.type.render().props.children.props.d}
                        stroke={green[700]}
                      />
                    </Svg>
                  </View>
                </View>
                <QA width={22} label="Hardness Test" report={hbw} />
                <QA width={26.5} label="Ultrasonic/Radiographic" report={ut} />
                <QA width={26.5} label="Mag. Particle/Penetrant" report={mpi} />
              </View>
            </View>
          </View>
        </View>
        {hydro &&
          _.map(hydro, (hydroChart, hydroChartId) => (
            <View
              style={styles.sectionContainer}
              wrap={false}
              key={hydroChartId}
            >
              <View style={styles.section} wrap={false}>
                <View style={styles.sectionTitle}>
                  <Text>Hydrostatic Pressure Test</Text>
                </View>
                <View style={[styles.sectionBody, styles.columns]}>
                  <View style={styles.column}>
                    <View style={styles.row}>
                      <Text>Tester:</Text>
                      <Text style={styles.bold}>{hydroChart.by}</Text>
                    </View>
                    <View style={styles.row}>
                      <Text>Date: </Text>
                      <Text style={styles.bold}>
                        {reformatDate(hydroChart.date)}
                      </Text>
                    </View>
                    <View style={styles.row}>
                      <Text>Chart: </Text>
                      <Text style={styles.bold}>
                        {hydroChart.files[0] ? (
                          <Text style={styles.link}>
                            (
                            <Link
                              href={hydroChart.files[0].downloadUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {hydroChart.title}
                            </Link>
                            )
                          </Text>
                        ) : (
                          hydroChart.title
                        )}
                      </Text>
                      {_.map(hydroChart.files, (report) => (
                        <Text style={styles.link} key={report.downloadUrl}>
                          (
                          <Link
                            href={report.downloadUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            link
                          </Link>
                          )
                        </Text>
                      ))}
                    </View>
                    <View style={styles.row}>
                      <Text>Result:</Text>
                      <Text style={{ ...styles.bold, color: "#43a047" }}>
                        PASS
                      </Text>
                    </View>
                  </View>
                  {hydroChart.holds && (
                    <View style={styles.charts}>
                      {_.map(hydroChart.holds, (hold, holdId) => {
                        return (
                          <View key={holdId} style={styles.chart}>
                            <Text style={{ textAlign: "center", fontSize: 8 }}>
                              {hold.intervals && hold.intervals[0].name}
                            </Text>
                            <Image src={hold.image} />
                          </View>
                        );
                      })}
                    </View>
                  )}
                </View>
              </View>
            </View>
          ))}
        <View style={styles.spacing} />
        <View
          style={{ ...styles.sectionContainer, marginTop: -4, marginBottom: 0 }}
        >
          <View style={styles.section} wrap={false}>
            <View style={styles.final}>
              <View style={styles.sectionTitle}>
                <Text>Result</Text>
              </View>
              <View style={{ ...styles.sectionBody }}>
                <Text style={styles.certify}>
                  {"We hereby certify that all item(s) were constructed," +
                    " inspected, and tested in compliance with Gulf-Pro Services’ Quality System. All accepted items meet" +
                    " or exceed the specific requirements to which they were designed. These requirements may include build" +
                    " code and/or industry standards, as well as GPS internal operating procedures."}
                </Text>
                <Text style={styles.bold}>
                  Certified by: Dustin Stein – Quality Director
                </Text>
                <View style={styles.approve}>
                  <Image src={signature} style={{ width: 125 }} />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const App = (props) => {
  //const { itemId } = props;
  // const [working, setWorking] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.jobItems);
  //const item = items && items[itemId];
  const materials = useSelector((s) => s.materials);
  const reports = useSelector((s) => s.jobReports);
  React.useEffect(() => dispatch(fetchMaterials()), [dispatch]);

  // if (!item) return null;

  if (_.size(materials) === 0) return null;
  // const cannotCertify = item && _.map(item.needs, need => Boolean(_.find(itemReports, itemReport => itemReport.type.abbr === need)));
  // console.log({cannotCertify})
  //Need to loop through job items, determine if items have all needed reports
  //track progress of cert creation and file upload.  (maybe use redux to add to item status)

  const generatePdfDocument = async (item, itemReports) => {
    const blob = await pdf(
      <Certificate
        item={item}
        itemReports={itemReports}
        materials={materials}
      />
    ).toBlob();
    return blob;
  };
  const makeCerts = () =>
    _.map(items, (item, itemId) => {
      const itemReports = _.pickBy(reports, (jr) => jr.items[itemId]);

      //===============================================
      //assemble all attachments into array (maybe do this in a cloud function):
      const getAttachments = async () => {
        const flatten =() => _.flattenDeep(
        _.map(itemReports, (report) => {
          const files = _.find(report, (i, iId) => iId === "files");
          console.log({files})
          const materialTrace = _.flatten(
            _.map(_.pick(materials, itemTrace), (i, iId) => i.traceDocs)
          );
          if (!isIterable(files) || !isIterable(materialTrace)) return (
            `Item ${item.asset} is missing an attachment for ${report.type.desc}: ${report.title}`
            )
          
          const allTrace = [...files, ...materialTrace];
          const removeUndefined = _.filter(allTrace, el => el !== undefined)
          return _.flattenDeep(_.uniqBy(removeUndefined, 'fileLocation'));
        })
      )
        try {
          const resArray = await flatten();
          const r = _.find(resArray, res => typeof res === 'string')
          return r 
          ? Promise.reject(r)
          : Promise.resolve(resArray);
        }
        catch(e) {
          console.warn(e)
        }
      };
      //===============================================
      const findTrace = (part) => {
        return _.map(part, (c) => {
          return c.materialId || "untraced";
        });
      };
      const itemTrace = [
        ...findTrace(item.connections),
        ...findTrace(item.bodies),
      ];
      const canCertify = !_.includes(
        item &&
          _.map(item.needs, (need) => {
            if (need === "MTRs") {
              return !_.includes(itemTrace, "untraced");
            }
            return Boolean(
              _.find(itemReports, (itemReport) => {
                return itemReport.type.abbr === need;
              })
            );
          }),
        false
      );

      const uploadStatusCallback = (f) => f;

      return (
        canCertify &&
        getAttachments()
        .then(attachmentLocations => generatePdfDocument(item, itemReports)
        .then((blob) => {
          return dispatch(
            uploadCert(
              itemId,
              item.asset,
              blob,
              attachmentLocations,
              uploadStatusCallback
            )
          )
        })
      )
      .catch(e=>enqueueSnackbar(e, {variant: 'error'}))
      )
    });
  return <div onClick={makeCerts}>Make Certs</div>
    // <PDFViewer width={760} height={1000}>
    // <Certificate
    // item={item}
    // itemReports={itemReports}
    // materials={materials}
    // />
    // </PDFViewer>


};


export default App;
