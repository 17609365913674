import { map, chain, filter, pickBy } from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Divider,
  Hidden,
} from "@mui/material";

import MaterialsNew from "components/materialsNew";

const Materials = ({ type }) => {
  const materials = useSelector((state) => pickBy(state.materials, { type }));
  const [open, setOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const materialTypes = chain(materials)
    .map((m) => m.description)
    .uniq()
    .sort()
    .value();
  const toggle = (id) => {
    setOpen((prev) => {
      setEditId(prev ? null : id || null);
      return !prev;
    });
  };
  return (
    <>
      <Button fullWidth onClick={() => toggle()}>
        Add Material
      </Button>
      {map(materialTypes, (materialType, materialId) => {
        return (
          <Accordion key={materialType}>
            <AccordionSummary>{materialType}</AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={3} sm={1}>
                  Qty
                </Grid>
                <Hidden only="xs">
                  <Grid item zeroMinWidth sm={1}>
                    UM
                  </Grid>
                </Hidden>
                <Hidden only="xs">
                  <Grid item zeroMinWidth sm={1}>
                    PO
                  </Grid>
                </Hidden>
                <Grid item xs={9} sm={3}>
                  Trace Id
                </Grid>
                <Hidden only="xs">
                  <Grid item zeroMinWidth sm={3}>
                    Material
                  </Grid>
                </Hidden>
                <Hidden only="xs">
                  <Grid item zeroMinWidth sm={3}>
                    Purchase Date
                  </Grid>
                </Hidden>
              </Grid>
              <Divider />
              {map(
                filter(
                  materials,
                  (material) => material.description === materialType
                ),
                (mat) => {
                  const { id, po, qty, um, traceNo, material, purchaseDate } =
                    mat;
                  return (
                    <React.Fragment key={id}>
                      <Grid container spacing={1} onClick={() => toggle(id)}>
                        <Grid item xs={3} sm={1}>
                          {qty}
                        </Grid>
                        <Hidden only="xs">
                          <Grid item zeroMinWidth sm={1}>
                            {um}
                          </Grid>
                        </Hidden>
                        <Hidden only="xs">
                          <Grid item zeroMinWidth sm={1}>
                            {po}
                          </Grid>
                        </Hidden>
                        <Grid item xs={9} sm={3}>
                          {traceNo}
                        </Grid>
                        <Hidden only="xs">
                          <Grid item zeroMinWidth sm={3}>
                            {material}
                          </Grid>
                        </Hidden>
                        <Hidden only="xs">
                          <Grid item zeroMinWidth sm={3}>
                            {purchaseDate}
                          </Grid>
                        </Hidden>
                      </Grid>
                      <Divider />
                    </React.Fragment>
                  );
                }
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
      <MaterialsNew open={open} onClose={toggle} editId={editId} type={type}/>
    </>
  );
};

export default Materials;
