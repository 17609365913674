import { map, reverse, values } from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Fab,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Add, Create } from "@mui/icons-material";

import { fetchJobs, cancelJobItemFetch, setOpenJobId } from "actions";

import JobsNew from "components/jobsNew";
import Job from "components/job";

const JobsList = () => {
  const dispatch = useDispatch();
  const [includeClosed, setIncludeClosed] = useState(false);
  const [open, setOpen] = useState(false);
  const openJobId = useSelector(state => state.openJobId);
  const [editId, setEditId] = useState(null);
  const jobs = useSelector((state) => state.jobs);
  const jobsReversed = reverse(values(jobs));
  const toggle = (id) => {
    dispatch(fetchJobs(includeClosed))
    setOpen((prev) => {
      setEditId(prev ? null : id);
      return !prev;
    });
  };
  const fetch = React.useCallback(
    () => dispatch(fetchJobs(includeClosed)),
    [dispatch, includeClosed]
  );
  useEffect(fetch, [fetch]);
  return (
    <Paper
      sx={{
        position: "relative",
        minHeight: (theme) => theme.spacing(6),
        paddingBottom: (theme) => theme.spacing(8),
      }}
      elevation={0}
    >
      <FormGroup>
        <FormControlLabel
        sx={{mt: 1, ml: 1}}
          control={
            <Switch
              checked={includeClosed}
              onChange={() => setIncludeClosed((prev) => !prev)}
            />
          }
          label="Include Closed"
        />
      </FormGroup>
      <List
        sx={{
          maxHeight: "calc(100vh - 360px)",
          overflow: "auto",
        }}
      >
        {map(jobsReversed, (job, jobIndex) => {
          const { jobId, description, date, status } = job;
          if (!includeClosed && status === "closed") return null;
          return (
            <ListItem key={jobId} button onClick={() => dispatch(setOpenJobId(jobId))}>
              <ListItemText primary={date} secondary={description} />
              <ListItemSecondaryAction>
                <IconButton onClick={() => toggle(jobId)}>
                  <Create />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <Fab
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
        }}
        color="primary"
        onClick={() => toggle()}
      >
        <Add />
      </Fab>
      <JobsNew open={open} onClose={toggle} editId={editId} />
      <Job
        jobId={openJobId}
        onClose={() => {
          dispatch(cancelJobItemFetch(openJobId));
          dispatch(setOpenJobId(null));
        }}
      />
    </Paper>
  );
};

export default JobsList;
