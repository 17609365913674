import React from "react";
// import { indigo } from "@mui/material/colors";
import { AppBar, Paper, Tabs, Tab, Box, Typography } from "@mui/material";

import JobsList from "components/jobsList";
import Settings from "components/settings";
import Materials from "components/materials";

const styles = {
  root: {
    flexGrow: 1,
    maxWidth: 768,
    margin: "0 auto 15px",
    backgroundColor: (theme) => theme.palette.background.paper,
  },
  header: {
    // color: indigo[700],
    textAlign: "center",
    margin: (theme) => theme.spacing(2),
    paddingTop: (theme) => theme.spacing(4),
  },
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

const Main = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Typography
        component="h1"
        variant="h5"
        sx={styles.header}
        color="primary"
      >
        Gulf-Pro Inventory Construction
      </Typography>
      <Paper sx={styles.root} elevation={1}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="fullWidth"
            indicatorColor="primary"
          >
            <Tab label="Jobs" {...a11yProps(0)} />
            <Tab label="Material Inventory" {...a11yProps(1)} />
            <Tab label="Settings" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <JobsList />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Materials />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Settings />
        </TabPanel>
      </Paper>
    </div>
  );
};

export default Main;
