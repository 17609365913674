/*global structuredClone*/
import {
  map,
  capitalize,
  size,
  pickBy,
  mapValues,
  isEqual,
  includes,
  pullAt,
  indexOf,
  pick,
  values as _values,
  flatten,
  omit,
  sortBy
} from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  TextField,
  Grid,
  MenuItem,
  ListItemText,
  ListItemButton,
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch,
  InputLabel,
  Card,
  CardContent,
  InputAdornment,
  Typography,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import { Create } from "@mui/icons-material";
import { Formik, Form } from "formik";
import { object, string, number } from "yup";
import { useConfirm } from "components/confirm";

import ItemType from "components/itemType";

import {
  fetchItemTypes,
  fetchSettings,
  createJobItem,
  updateJobItem,
} from "actions";

const ComposedTextField = (props) => {
  const ref = React.createRef();
  const { setFieldValue, values, v, i, material } = props;
  const settings = useSelector(
    (state) => state.settings[material ? "materials" : v]
  );
  const type = material ? "material" : "description";
  return (
    <TextField
      ref={ref}
      value={values[v][i][type]}
      onChange={(e) => {
        const { value } = e.target;
        const el = { ...values[v][i], [type]: value };
        values[v][i] = el;
        setFieldValue(v, values[v]);
      }}
      label={`${capitalize(v)} ${i + 1} ${material ? "Material" : ""}`}
      fullWidth
      select
      sx={{ marginTop: (theme) => material && theme.spacing(1) }}
    >
      <MenuItem value="" />
      {map(sortBy(settings, 'description'), ({ description, id }) => (
        <MenuItem value={description} key={id}>
          {description}
        </MenuItem>
      ))}
    </TextField>
  );
};

const SetItem = ({ open, onClose, editId, jobId, clone }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [build, setBuild] = React.useState(false);
  const [cloneAmount, setCloneAmount] = React.useState(1);
  const [editTypeId, setEditTypeId] = React.useState(null);
  const [editAll, setEditAll] = React.useState(true);
  const { um } = useSelector((state) => state.settings);
  const { jobItems } = useSelector((state) => state);
  const editJobItem = jobItems && jobItems[editId];
  const defaultObject = {
    description: "",
    material: "",
    materialId: "",
    traceNo: "",
    traceDoc: "",
  };
  const editItem =
    editJobItem && clone
      ? {
          ...editJobItem,
          asset: "",
          coc: null,
          dataBook: null,
          attachmentLocations: null,
          connections: map(editJobItem.connections, (connection, vcIndex) => ({
            ...connection,
            materialId: "",
          })),
          bodies: map(editJobItem.bodies, (body, vcIndex) => ({
            ...body,
            materialId: "",
          })),
        }
      : editJobItem;
  const stripUniqValues = (obj) => ({
    ...obj,
    asset: "",
    connections: map(obj.connections, (o) => o && o.description),
    bodies: map(obj.bodies, (o) => o && o.description),
    ports: map(obj.ports, (o) => o && o.description),
  });
  const similarItems = mapValues(jobItems, (v) => stripUniqValues(v));
  const editCompare = editItem && stripUniqValues(editItem);
  const compare = pickBy(similarItems, (item) => isEqual(item, editCompare));
  const toggleBuild = (id) => {
    setBuild((prev) => {
      setEditTypeId(prev ? null : id || null);
      return !prev;
    });
  };

  const itemTypes = useSelector((state) => state.itemTypes);
  const reportTypes = useSelector((state) => state.reportTypes);
  const fetch = React.useCallback(() => {
    dispatch(fetchItemTypes());
    dispatch(fetchSettings());
  }, [dispatch]);
  React.useEffect(() => {
    fetch();
  }, [fetch]);
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Formik
        enableReinitialize
        initialValues={
          structuredClone(editItem) || {
            asset: "",
            type: "",
            description: "",
            length: 0,
            lengthUnits: "",
            connections: [],
            bodies: [],
            ports: [],
            schematic: "",
            temperature: "",
            materialClass: "",
            mfgSpec: "",
            pressureRating: 0,
            testPressure: 0,
            serviceType: "Standard",
            needs: map(reportTypes, (rt) => rt.abbr),
            jobId,
          }
        }
        validationSchema={object().shape({
          type: string().required(),
          description: string().required(),
          length: number().when("bodies", {
            is: (bodyArray) => {
              const descriptions = map(
                bodyArray,
                (el) => el.description && el.description.toLowerCase()
              );
              return includes(descriptions.join(","), "pipe");
            },
            then: (schema) =>
              schema
                .moreThan(0, "A length is required when using piping")
                .required("A length is required when using piping"),
          }),
        })}
        onSubmit={(values, actions) => {
          const done = () => {
            actions.resetForm();
            onClose();
          };
          const newItem = () => {
            const cnt = parseInt(cloneAmount);
            for (var i = 1; i <= cnt; i++) {
              console.log({ i, cnt });
              dispatch(createJobItem(values));
            }
            return done();
          };
          const updateItem = () => {
            //we must check for tracability and connection info.  If there's trace info, deny update and warn user
            const invJobItem = (item) =>
              pick(item, ["connections", "bodies", "length", "lengthUnits"]);
            const invValues = pick(values, [
              "connections",
              "bodies",
              "length",
              "lengthUnits",
            ]);
            const invSame = isEqual(invJobItem(editJobItem), invValues);
            const hasTrace = (item) => {
              const traceString = map(
                flatten(_values(pick(item, ["connections", "bodies"]))),
                (v) => v.materialId
              ).join("");
              const canUpdate =
                typeof traceString === "string" && traceString.length === 0;
              return canUpdate;
            };
            const warn = () => {
              return confirm({
                title: "Unable to update",
                description:
                  "This item has linked material.  Please unselect materials for connections and bodies prior to adjustment",
                confirmationText: "Ok",
                cancellationText: "Cancel",
              })
                .then(done)
                .catch(done);
            };
            if (!invSame && !hasTrace(values)) {
              return warn();
            }
            return editAll && !clone
              ? map(compare, (jobItem, jobItemId) => {
                  // const { asset, connections, bodies } = jobItem;
                  const assetSame = isEqual(invJobItem(editJobItem), invValues);
                  if (!assetSame && !hasTrace(jobItem)) return warn();
                  return dispatch(
                    updateJobItem(
                      { ...omit(values, ["asset", "connections", "bodies"]) },
                      jobItemId,
                      done
                    )
                  );
                })
              : dispatch(updateJobItem(values, editId, done));
          };
          return editId && !clone ? updateItem() : newItem();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setValues,
          setFieldValue,
        }) => {
          const processChanges = (value) => {
            setValues({
              ...values,
              type: value,
              connections: value
                ? [...Array(itemTypes[value].connections).fill(defaultObject)]
                : [],
              bodies: value
                ? [...Array(itemTypes[value].bodies).fill(defaultObject)]
                : [],
              ports: value
                ? [...Array(itemTypes[value].ports).fill(defaultObject)]
                : [],
            });
          };
          return (
            <Form>
              <DialogTitle>
                {editId ? "Update Item(s)" : "Create Item(s)"}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{ marginTop: (theme) => theme.spacing(1) }}
                  >
                    <TextField
                      value={values.asset}
                      onChange={handleChange}
                      name="asset"
                      label="Asset Number"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{ marginTop: (theme) => theme.spacing(1) }}
                  >
                    <TextField
                      value={values.description}
                      onChange={handleChange}
                      name="description"
                      label="Item Description"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={handleBlur}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ marginTop: (theme) => theme.spacing(1) }}
                  >
                    <FormControl
                      fullWidth
                      error={Boolean(touched.type && errors.type)}
                    >
                      <InputLabel id="item-type-label" shrink>
                        Item Type
                      </InputLabel>
                      <Select
                        labelId="item-type-label"
                        disabled={Boolean(editId)}
                        name="type"
                        label="Item Type"
                        notched
                        value={values.type}
                        renderValue={(v) =>
                          itemTypes[v] && itemTypes[v].description
                        }
                        fullWidth
                        onChange={(e) => {
                          const { value } = e.target;
                          return value === "create"
                            ? handleBlur(e)
                            : processChanges(value);
                        }}
                        onClose={() => handleBlur("type")}
                      >
                        <MenuItem value="" />
                        {map(itemTypes, (itemType, itemTypeId) => {
                          return (
                            <MenuItem key={itemTypeId} value={itemTypeId}>
                              <ListItemText>
                                {itemType.description}
                              </ListItemText>
                              <ListItemButton
                                sx={{
                                  height: (theme) => theme.spacing(4),
                                  maxWidth: (theme) => theme.spacing(4),
                                  borderRadius: (theme) => theme.spacing(2),
                                  padding: (theme) => theme.spacing(0.5),
                                }}
                                onClick={() => toggleBuild(itemTypeId)}
                              >
                                <Create />
                              </ListItemButton>
                            </MenuItem>
                          );
                        })}
                        <MenuItem value="create">
                          <Button onClick={() => toggleBuild()}>
                            Build New Type
                          </Button>
                        </MenuItem>
                      </Select>
                      {touched.type && errors.type && (
                        <FormHelperText>{errors.type}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {values.type &&
                      ["connections", "bodies", "ports"].map((v) => (
                        <Card
                          key={v}
                          sx={{
                            backgroundColor: (theme) =>
                              `${theme.palette.background.paper}44`,
                            marginBottom: (theme) => theme.spacing(1),
                          }}
                        >
                          <CardContent>
                            <Typography>{capitalize(v)}</Typography>
                            <Grid
                              container
                              spacing={1}
                              sx={{ marginTop: (theme) => theme.spacing(1) }}
                            >
                              {map(values[v], (_, i) => {
                                const aSize = size(values[v]);
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={aSize > 3 ? 6 : Math.floor(12 / aSize)}
                                    md={Math.floor(12 / aSize)}
                                    key={i}
                                  >
                                    <ComposedTextField
                                      values={values}
                                      setFieldValue={setFieldValue}
                                      v={v}
                                      i={i}
                                    />
                                    <ComposedTextField
                                      values={values}
                                      setFieldValue={setFieldValue}
                                      v={v}
                                      i={i}
                                      material
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </CardContent>
                        </Card>
                      ))}
                  </Grid>
                  <Grid item xs={8} sm={4} md={3}>
                    <TextField
                      name="length"
                      value={values.length}
                      type="number"
                      onChange={handleChange}
                      label="Length (Piping Only)"
                      fullWidth
                      onBlur={handleBlur}
                      error={Boolean(touched.length && errors.length)}
                      helperText={touched.length && errors.length}
                    />
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    <TextField
                      name="lengthUnits"
                      value={values.lengthUnits}
                      onChange={handleChange}
                      label="Length Units"
                      select
                      fullWidth
                    >
                      <MenuItem value="" />
                      {map(um, ({ description, id }) => (
                        <MenuItem key={id} value={description}>
                          {description}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3}>
                    <TextField
                      name="temperature"
                      value={values.temperature}
                      onChange={handleChange}
                      label="Temperature"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={3}>
                    <TextField
                      name="materialClass"
                      value={values.materialClass}
                      onChange={handleChange}
                      label="Material Class"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={3}>
                    <TextField
                      name="mfgSpec"
                      value={values.mfgSpec}
                      onChange={handleChange}
                      label="Manufacturing Spec"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={3}>
                    <TextField
                      name="serviceType"
                      value={values.serviceType}
                      onChange={handleChange}
                      label="Service Type"
                      fullWidth
                      select
                    >
                      <MenuItem value="Standard">Standard</MenuItem>
                      <MenuItem value="H₂S">H₂S</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3}>
                    <TextField
                      name="pressureRating"
                      value={values.pressureRating}
                      type="number"
                      onChange={handleChange}
                      label="Pressure Rating"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">psi</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} md={3}>
                    <TextField
                      name="testPressure"
                      value={values.testPressure}
                      type="number"
                      onChange={handleChange}
                      label="Integrity Test Pressure"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">psi</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <FormControl component="fieldset" variant="standard">
                      <FormLabel component="legend">Required info</FormLabel>
                      <FormGroup>
                        {map(reportTypes, (reportTypeObj, reportTypeIndex) => {
                          const reportType = reportTypeObj.abbr;
                          const checked = includes(values.needs, reportType);
                          const newElSize = () =>
                            checked
                              ? pullAt(
                                  values.needs,
                                  indexOf(values.needs, reportType)
                                )
                              : values.needs && values.needs.push(reportType);
                          const toggleCheck = () => {
                            setFieldValue(
                              "needs",
                              newElSize() ? values.needs : [reportType]
                            );
                          };
                          return (
                            <FormControlLabel
                              key={reportTypeIndex}
                              control={
                                <Switch
                                  checked={checked}
                                  onChange={toggleCheck}
                                />
                              }
                              label={reportTypeObj.desc}
                            />
                          );
                        })}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  {(clone || !Boolean(editId)) && (
                    <Grid
                      item
                      xs={12}
                      sx={{ marginTop: (theme) => theme.spacing(1) }}
                    >
                      <TextField
                        value={cloneAmount}
                        type="number"
                        onChange={(e) => setCloneAmount(e.target.value)}
                        label="New Item Qty"
                        fullWidth
                        helperText="Enter qty of items to create"
                      />
                    </Grid>
                  )}
                  {Boolean(editId) && !clone && (
                    <Grid
                      item
                      xs={12}
                      sx={{ marginTop: (theme) => theme.spacing(1) }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={editAll}
                              onChange={() => setEditAll((prev) => !prev)}
                            />
                          }
                          label={`Edit other ${
                            size(compare) - 1
                          } similar items`}
                        />
                      </FormGroup>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" color="primary" variant="outlined">
                  {editId ? (clone ? "Clone" : "Update") : "Create"}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
      <ItemType
        open={build}
        onClose={toggleBuild}
        editId={editTypeId}
        reopenOnSave={(id) => setEditTypeId(id)}
      />
    </Dialog>
  );
};

export default SetItem;
