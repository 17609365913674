import { map, capitalize, sortBy } from "lodash";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Add } from "@mui/icons-material";

import { setSettingValue } from "actions";

const SettingsDetail = ({ type }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.settings && state.settings[type]);
  const [value, setValue] = React.useState("");
  const [editId, setEditId] = React.useState(null);
  const handleChange = (e) => setValue(e.target.value);
  const submit = () => {
    return value
      ? dispatch(
          setSettingValue(type, { description: value, id: editId }, () => {
            setValue("");
            setEditId(null);
          })
        )
      : (f) => f;
  };
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <TextField
          onChange={handleChange}
          value={value}
          label={editId ? `Edit ${capitalize(type)}` : "Add"}
        />
        <IconButton
          size="large"
          sx={{ margin: (theme) => theme.spacing(0.5) }}
          type="submit"
        >
          <Add />
        </IconButton>
      </form>
      <List
        sx={{
          maxHeight: 400,
          overflow: "auto",
        }}
      >
        {map(
          sortBy(data, (v) => v.description),
          (record) => {
            const { id, description } = record;
            return (
              <ListItem
                key={id}
                onClick={() => {
                  setEditId(id);
                  setValue(description);
                }}
              >
                <ListItemText primary={description} />
              </ListItem>
            );
          }
        )}
      </List>
    </>
  );
};

export default SettingsDetail;
