import { map, pickBy, includes, size } from "lodash";
import { database } from "firebase_config";
import { ref, child, get } from "firebase/database";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
} from "@mui/material";

import { updateJobReport } from "actions";

const LinkHydro = ({ open, onClose, jobId, type }) => {
  const dispatch = useDispatch();
  const [chart, setChart] = useState("");
  const [chartData, setChartData] = useState({});
  const jobItems = useSelector((state) => state.jobItems);
  useEffect(() => {
    if (chart.length > 5)
      get(child(ref(database), `/hydrotest/tests/${chart}`)).then(
        (snapshot) => {
          return setChartData(snapshot.val() || {});
        }
      );
  }, [chart]);
  const linkChartToReport = () => {
    const {
      tester,
      report,
      items,
      timestamp,
      holds,
      targetWp,
      targetTp,
      status,
    } = chartData;
    const complete = status === "Complete";
    const dateFormatted = (stamp) => {
      const rn = new Date(stamp);
      const y = rn.getFullYear();
      const m = `0${rn.getMonth() + 1}`.slice(-2);
      const d = `0${rn.getDate()}`.slice(-2);
      return `${y}-${m}-${d}`;
    };
    const chartDataItems = map(items, (item) =>
      item.associated ? "" : item.asset
    );
    const linkedItems = pickBy(jobItems, (jobItem) => {
      return (
        jobItem.asset && includes(chartDataItems, jobItem.asset.toString())
      );
    });
    console.log({ linkedItems, jobItems });
    if (items && complete && size(linkedItems) > 0) {
      const reportValues = {
        by: tester,
        date: dateFormatted(timestamp),
        title: chart,
        type,
        jobId,
        holds,
        targetWp,
        targetTp,
        files: [
          {
            downloadUrl: report,
            fileName: `${chart}.pdf`,
            fileLocation: `testCharts/${chart}.pdf`
          },
        ],
        items: linkedItems,
      };
      dispatch(updateJobReport(reportValues, chart, onClose));
    }
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Link Hydro Chart</DialogTitle>
      <DialogContent>
        <TextField
          value={chart}
          onChange={(e) => setChart(e.target.value.toUpperCase())}
          label="Chart No."
          helperText="Enter the test chart number to link"
          fullWidth
          sx={{ marginTop: (theme) => theme.spacing(1) }}
        />
        <List>
          {map(chartData.items, (item, itemId) => (
            <ListItem key={itemId}>
              <ListItemText primary={item.asset} secondary={item.description} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={linkChartToReport} variant="contained" color="primary">
          Link
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkHydro;
