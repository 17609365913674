import { combineReducers } from "redux";
import authUser from "reducers/authUser";
import jobs from "reducers/jobs";
import jobItems from "reducers/jobItems";
import itemTypes from "reducers/itemTypes";
import settings from "reducers/settings";
import materials from "reducers/materials";
import reportTypes from "reducers/reportTypes";
import jobReports from "reducers/jobReports";
import openJobId from "reducers/openJobId";

export default combineReducers({
  authUser,
  jobs,
  jobItems,
  itemTypes,
  settings,
  materials,
  reportTypes,
  openJobId,
  jobReports
});
