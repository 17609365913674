import { map, filter } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  Autocomplete,
  TextField,
  MenuItem,
  Radio,
  FormControlLabel,
  Switch,
  FormGroup,
  RadioGroup,
  FormControl,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { Formik, Form } from "formik";
import { object, string, number } from "yup";

import FileDrop from "components/fileDrop";
import { useConfirm } from "components/confirm";

import { setMaterial, deleteMaterial } from "actions";

const MaterialNew = ({ open, onClose, editId, type }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [duplicate, setDuplicate] = React.useState(false);
  const [duplicateDesc, setDuplicateDesc] = React.useState('');
  const material = useSelector(
    (state) => state.materials && state.materials[editId]
  );
  const settings = useSelector((state) => state.settings);
  // const materialTypes = chain(materials)
  //   .map((m) => m.description)
  //   .uniq()
  //   .sort()
  //   .value();
  // console.log({ materialTypes });
  const handleDelete = () => {
    confirm({ description: "Deleting this material cannot be undone" }).then(
      () => dispatch(deleteMaterial(editId, onClose))
    );
    // .catch(onClose)
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={
          material
            ? { ...material, traceDocs: material.traceDocs || [] }
            : {
                description: "",
                material: "",
                type: type,
                po: "",
                qty: 0,
                um: "",
                traceNo: "",
                purchaseDate: "",
                traceDocs: [],
              }
        }
        onSubmit={(values, actions) => {
          if (duplicate && duplicateDesc) {
            dispatch(setMaterial({...values, description: duplicateDesc}))
          }
          return dispatch(
            setMaterial({ ...values, id: editId }, () => {
              actions.resetForm();
              onClose();
            })
          );
        }}
        validationSchema={object().shape({
          description: string().required("A Description is Required"),
          material: string().required("A Material Selection is Required"),
          qty: number().required("A Quantity is Required"),
        })}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          const options = [
            ...map(settings[values.type], (setting) => setting.description).sort(),
            "",
          ];
          return (
            <Form>
              <DialogTitle>
                {editId
                  ? "Update Exising Material"
                  : "Add New Material to inventory"}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="type"
                        onChange={handleChange}
                        value={values.type}
                      >
                        <FormControlLabel
                          value="connections"
                          control={<Radio />}
                          label="Connection"
                        />
                        {/*<FormControlLabel
                          value="materials"
                          control={<Radio />}
                          label="Materials"
                        />*/}
                        <FormControlLabel
                          value="bodies"
                          control={<Radio />}
                          label="Pipe/Body"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      autoComplete
                      onChange={(e, newValue) => {
                        const val = newValue;
                        setFieldValue("description", val);
                      }}
                      // onClose={(e, newValue) => {
                      //   setFieldValue("description", newValue.description);
                      // }}
                      name="description"
                      value={values.description}
                      // freeSolo
                      options={options}
                      // getOptionLabel={option => option.description}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="Description"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            helperText={
                              touched.description && errors.description
                            }
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={values.material}
                      label="Material"
                      select
                      onChange={handleChange}
                      name="material"
                      fullWidth
                      error={Boolean(touched.material && errors.material)}
                      helperText={touched.material && errors.material}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {map(settings.materials, (material) => {
                        const { description, id } = material;
                        return (
                          <MenuItem key={id} value={description}>
                            {description}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={values.po}
                      label="Purchase Order"
                      onChange={handleChange}
                      name="po"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      value={values.qty}
                      label="Quanitiy"
                      type="number"
                      onChange={handleChange}
                      name="qty"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(touched.qty && errors.qty)}
                      helperText={touched.qty && errors.qty}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={values.um}
                      label="UM"
                      onChange={handleChange}
                      name="um"
                      select
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(touched.um && errors.um)}
                      helperText={touched.um && errors.um}
                    >
                      {map(settings.um, (material) => {
                        const { description, id } = material;
                        return (
                          <MenuItem key={id} value={description}>
                            {description}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={values.purchaseDate}
                      label="Purchase Date"
                      onChange={handleChange}
                      type="date"
                      name="purchaseDate"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={values.traceNo}
                      label="Traceability Id"
                      onChange={handleChange}
                      name="traceNo"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={Boolean(touched.traceNo && errors.traceNo)}
                      helperText={touched.traceNo && errors.traceNo}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FileDrop
                      saveLocation="materials/batches"
                      onUpload={(fileData) => {
                        values.traceDocs.push(fileData);
                        setFieldValue("traceDocs", values.traceDocs);
                      }}
                      allowed={{ "application/pdf": [] }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      {map(values.traceDocs, (doc, index) => {
                        const { downloadUrl, fileName } = doc;
                        return (
                          <ListItem
                            key={downloadUrl}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() =>
                                  setFieldValue(
                                    "traceDocs",
                                    filter(values.traceDocs, (v, i) => {
                                      return i !== index;
                                    })
                                  )
                                }
                              >
                                <DeleteForever />
                              </IconButton>
                            }
                          >
                            <ListItemText
                              primary={
                                <a
                                  href={downloadUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {fileName}
                                </a>
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  {values.type === 'connections' && !editId &&
                  <>
                  <Grid item xs={12} sm={3}>
                    <FormGroup>
                      <FormControlLabel control={
                        <Switch
                          checked={duplicate}
                          onChange={() => setDuplicate(prev => !prev)}
                        />
                      } label="Duplicate?" />
                    </FormGroup>
                  </Grid>
                  {duplicate &&
                  <Grid item xs={12} sm={9}>
                    <TextField
                      value={duplicateDesc}
                      onChange={e => setDuplicateDesc(e.target.value)}
                      label="Duplicate Connection"
                      select
                      fullWidth
                    >
                      <MenuItem value="" />
                      {map(options, option => {
                      if(
                        option.slice(0,5) === values.description.slice(0,5)
                        && option !== values.description
                      ) {
                        return (
                          <MenuItem key={option} value={option}>{option}</MenuItem>
                        )
                      }})}
                    </TextField>
                  </Grid>}
                  </>
                  }
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                {editId && (
                  <Button
                    onClick={handleDelete}
                    variant="contained"
                    color="secondary"
                  >
                    Delete
                  </Button>
                )}
                <Button type="submit" variant="contained" color="primary">
                  {editId ? "Update" : "Submit"}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default MaterialNew;
