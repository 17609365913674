import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { TextField } from "@mui/material";

import { uploadFile } from "actions";

function FileDrop({ saveLocation, onUpload, allowed }) {
  const dispatch = useDispatch();
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.map((file) => {
        return dispatch(uploadFile(saveLocation, file, onUpload));
      });
    },
    [dispatch, onUpload, saveLocation]
  );
  const accept = {
    "image/jpeg": [],
    "image/png": [],
    ...allowed,
  };
  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({ onDrop, accept });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <TextField
        disabled={!isDragActive}
        fullWidth
        placeholder={
          isDragActive
            ? "Drop the files here ..."
            : "Drag 'n' drop some files here, or click to select files"
        }
        sx={{
          "& input": isDragActive
            ? {
                boxShadow: (theme) => theme.shadows[6],
                border: "solid",
                borderWidth: 0.5,
                borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                borderColor: isDragAccept ? "green" : "red",
              }
            : {
                border: "dashed",
                borderWidth: 0.5,
                borderRadius: (theme) => `${theme.shape.borderRadius}px`,
              },
        }}
      />
    </div>
  );
}

export default FileDrop;
