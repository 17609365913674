import { map, omit, pullAt, size, mapValues } from "lodash";
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Alert,
  Divider,
  Typography
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useToday } from "customHooks";
import { object, string, mixed, array } from "yup"

import { createJobReport, updateJobReport } from "actions";
import ItemMtrs from "components/itemMtrs";
import LinkHydro from "components/linkHydro";

import FileDrop from "components/fileDrop";

const JobReport = (props) => {
  const { onClose, open, type, jobId } = props;
  const dispatch = useDispatch();
  const [openHydro, setOpenHydro] = React.useState(false);
  const jobItems = useSelector((state) => state.jobItems);
  // const jobReports = useSelector((state) => state.jobReports);
  const { reportId } = type;
  const editReport = useSelector(
    (state) => state.jobReports && state.jobReports[reportId]
  );
  const today = useToday();
  const toggleHydro = () => setOpenHydro((prev) => !prev);
  if (type && type.abbr === "MTRs") return <ItemMtrs {...props} />;
  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={
          reportId
            ? {
                ...editReport,
                files: editReport.files ? [...editReport.files] : [],
                items: editReport.items ? { ...editReport.items } : {},
              }
            : {
                type,
                items: {},
                title: "",
                date: today,
                by: "",
                files: [],
                jobId,
              }
        }
        validationSchema={
          object().shape({
            title: string().required('Title is a required field'),
            date: string().required(),
            by: string().required('Inspector is a required field'),
            items: mixed().test(
            'has-properties',
            'At least one item must be selected',
            obj => Object.keys(obj).length > 0),
            files: array().min(1, 'You must include at least one file attachment')
          })
        }
        onSubmit={(values, actions) => {
          const cb = () => {
            actions.resetForm();
            onClose();
          };
          return dispatch(
            reportId
              ? updateJobReport(values, reportId, cb)
              : createJobReport(values, cb)
          );
        }}
      >
        {({ values, handleChange, setFieldValue, touched, errors, setValues }) => {
          const allItemsChecked = size(jobItems) === size(values.items);
          const checkAllItems = (check) => {
            setValues({...values, items: check ? mapValues(jobItems, v => true) : {}})
          }
          return (
            <Form>
              <DialogTitle>{type.desc}</DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={1}
                  sx={{ marginTop: (theme) => theme.spacing(1) }}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      fullWidth
                      label="Report"
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="date"
                      value={values.date}
                      onChange={handleChange}
                      fullWidth
                      label="Report Date"
                      type="date"
                      error={Boolean(touched.date && errors.date)}
                      helperText={touched.date && errors.date}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="by"
                      value={values.by}
                      onChange={handleChange}
                      fullWidth
                      label="Tester/Inspector"
                      error={Boolean(touched.by && errors.by)}
                      helperText={touched.by && errors.by}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FileDrop
                      saveLocation={`jobReports/${jobId}/${type.abbr}`}
                      onUpload={(fileData) => {
                        values.files.push(fileData);
                        setFieldValue("files", values.files);
                      }}
                      allowed={{ "application/pdf": [] }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {type && type.abbr === "HYDRO" && (
                      <Button onClick={toggleHydro}>Link Hydro</Button>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <List>
                      {map(values.files, (file, fileIndex) => {
                        const { downloadUrl, fileName, fileLocation } = file;
                        return (
                          <ListItem
                            key={downloadUrl}
                            secondaryAction={
                              fileLocation && (
                                <IconButton
                                  edge="end"
                                  onClick={() => {
                                    pullAt(values.files, fileIndex);
                                    setFieldValue("files", values.files);
                                  }}
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              )
                            }
                          >
                            <ListItemText
                              primary={
                                <a
                                  href={downloadUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {fileName}
                                </a>
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={allItemsChecked}
                                onChange={() => {
                                  checkAllItems(!allItemsChecked)
                                }}
                                size="small"
                              />
                            }
                            label={
                              <Typography variant="caption">{allItemsChecked ? `Uncheck All` : 'Check All'}</Typography>
                            }
                          />
                          <Divider />
                      {map(jobItems, (jobItem, jobItemId) => {
                        const onReport = Boolean(values.items[jobItemId]);
                        return (
                          <FormControlLabel
                            key={jobItemId}
                            control={
                              <Checkbox
                                checked={onReport}
                                onChange={() => {
                                  const updatedItems = onReport
                                    ? omit(values.items, jobItemId)
                                    : { ...values.items, [jobItemId]: true };
                                  setFieldValue("items", updatedItems);
                                }}
                              />
                            }
                            label={jobItem.asset ? `${jobItem.description} (${jobItem.asset})` : jobItem.description}
                          />
                        );
                      })}
                    </FormGroup>
                  </Grid>
                  {Boolean(touched.files && errors.files)
                  && <Grid item xs={12}>
                    <Alert severity="warning">{errors.files}</Alert>
                  </Grid>}
                  {Boolean(touched.items && errors.items)
                  && <Grid item xs={12}>
                    <Alert severity="warning">{errors.items}</Alert>
                  </Grid>}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={editReport && !!editReport.holds}
                >
                  {editReport ? "Update" : "Submit"}
                </Button>
              </DialogActions>
              <LinkHydro
                open={openHydro}
                onClose={toggleHydro}
                jobId={jobId}
                type={type}
              />
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default JobReport;
